import React, { Component }  from "react";
import { Form, InputGroup, Row, Col, Card, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import CommentListItem from "../components/CommentListItem";
import InfiniteList from "../components/InfiniteList";
import * as moment from 'moment'
import "./FeedListItem.css"

export default class FeedListItem extends Component {
  constructor(props) {
    super(props);

    this.handleCommentKeyPress = this.handleCommentKeyPress.bind(this);

    this.state = {
      isLoading: false,
      showComments: false,
      comment: "",
      listItemData: this.props.listItemData || {},
      onLikeClick: this.props.onLikeClick,
      onUnlikeClick: this.props.onUnlikeClick
    };
  }

  addComment() {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {
        content: this.state.comment
      }
    }

    return API.post("profiles", "/activity/" + this.state.listItemData.dbId.substring(1) + "/comment", request);
  }

  handleCommentKeyPress(target) {
    if(target.charCode==13){
      if(this.state.comment) {
        this.addComment();
      }
    }
  }

  handleCommentChange = event => {
    this.setState({
      comment: event.target.value
    });
  }

  render() {
    const {
      listItemData
    } = this.state;
    return(
      <>
        <Card className="feed-card">
          <Card.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="mr-2">
                            <Avatar rounded currentprofile={listItemData.author} avatarsize='small'/>
                        </div>
                        <div className="ml-2">
                          <div className="header-row">
                            <LinkContainer className="groupName" to={'/User/' + listItemData.author.authorId.substring(1)} >
                              <a>{listItemData.author.fullName}</a>
                            </LinkContainer>
                          </div>
                          <div className="header-row">
                            <LinkContainer className="h7 text-muted" to={'/Group/' + listItemData.groupId.substring(1)} >
                              <a>{listItemData.groupName}</a>
                            </LinkContainer>
                          </div>
                        </div>
                    </div>
                    <div>
                        <div className="dropdown">
                            <button className="btn btn-link dropdown-toggle" type="button" id="gedf-drop1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-ellipsis-h"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="gedf-drop1">
                                <div className="h6 dropdown-header">Configuration</div>
                                <a className="dropdown-item" href="#">Save</a>
                                <a className="dropdown-item" href="#">Hide</a>
                                <a className="dropdown-item" href="#">Report</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <h3 className="card-title">{listItemData.name}</h3>
                <div className="h7 text-muted">
                    {moment(listItemData.activityDate).fromNow()}
                </div>
                <p className="card-text">
                    {listItemData.description}
                </p>
                <Row>
                  <Col>
                    {listItemData.likesCount > 0 &&
                    <p>
                      <i className="fa fa-thumbs-up"></i> {listItemData.likesCount}
                    </p>
                    }
                  </Col>
                  <Col>
                    <div className="text-muted text-right h7 mb-2"> <i className="fa fa-clock-o"></i>{moment(listItemData.createdDate).fromNow()}</div>
                  </Col>
                </Row>

                {listItemData.liked &&
                  <a href="#" className="card-link" onClick={event => {this.state.onUnlikeClick(listItemData.dbId)}}>
                    <i className="far fa-thumbs-up"></i> Unlike
                  </a>
                }
                {listItemData.liked == false &&
                  <a href="#" className="card-link" onClick={event => {this.state.onLikeClick(listItemData.dbId)}}>
                    <i className="far fa-thumbs-up"></i> Like
                  </a>
                }
                <a onClick={event => {this.setState({showComments: !this.state.showComments})}} className="card-link"><i className="far fa-comment"></i> Comment</a>
                {this.state.showComments &&
                  <>
                    <hr/>
                    <Row>
                      <Col>
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="newComment">
                          <InputGroup>
                            <Avatar rounded currentprofile={this.props.currentProfile} avatarsize='micro'/>
                            <Form.Control
                              className="add-comment-input"
                              type="text"
                              onKeyPress={this.handleCommentKeyPress}
                              onChange={this.handleCommentChange}
                              placeholder="Write a comment"/>
                          </InputGroup>
                        </Form.Group>
                      </Form>
                      </Col>
                    </Row>
                    <InfiniteList key={this.state.stateBustingKey} listComponent={CommentListItem} object={'groups'} route={'/activity/' + listItemData.dbId.substring(1) + '/comments'}/>
                  </>
                }
            </Card.Body>
        </Card>
      </>
    );
  }
}
