import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../containers/Footer.js"
import "./Privacy.css"

export default class Privacy extends Component {

  render() {
      return (
        <>
          <Container className="privacy-container">
            <h2>Yompster Privacy Statement</h2>
            <br/>
            <p>Your privacy is important to us. This privacy statement explains the personal data Yompster processes, how Yompster processes it and for what purposes.</p>
            <p>Please read the product-specific details in this privacy statement, which provide additional relevant information. This statement applies to the interactions Yompster has with you and the Yompster products listed below, as well as other Yompster products that display this statement.</p>
            <br/>
            <h5>Personal data we collect</h5>
            <hr/>
            <p>
            Yompster collects data from you, through our interactions with you and through our products. You provide some of this data directly,
            and we get some of it by collecting data about your interactions, use and experiences with our products. The data we collect
            depends on the context of your interactions with Yompster and the choices that you make, including your privacy settings and the
            products and features that you use. We also obtain data about you from third parties.
            </p>
            <p>
            You have choices when it comes to the technology you use and the data you share. When we ask you to provide personal data, you can
            decline. Many of our products require some personal data to provide you with a service. If you choose not to provide data necessary to
            provide you with a product or feature, you cannot use that product or feature. Likewise, where we need to collect personal data by law
            or to enter into or carry out a contract with you, and you do not provide the data, we will not be able to enter into the contract; or
            if this relates to an existing product you’re using, we may have to suspend or cancel it. We will notify you if this is the case at the
            time. Where providing the data is optional, and you choose not to share personal data, features like personalisation that use such data
            will not work for you.
            </p>
            <br/>
            <h5>How we use personal data</h5>
            <hr/>
            <p>
            Yompster uses the data we collect to provide you with rich, interactive experiences. In particular, we use data to:
            </p>
            <ul>
              <li>
                Provide our products, which includes updating, securing and troubleshooting, as well as providing support. It also
                includes sharing data, when it is required to provide the service or carry out the transactions you request.
              </li>
              <li>
                Improve and develop our products.
              </li>
              <li>
                Personalise our products and make recommendations.
              </li>
              <li>
                Advertise and market to you, which includes sending promotional communications, targeting advertising and presenting you
                with relevant offers.
              </li>
            </ul>
            <p>
            We also use the data to operate our business, which includes analysing our performance, meeting our legal obligations, developing our workforce and doing research.
            </p>
            <p>
            In carrying out these purposes, we combine data we collect from different contexts (for example, from your use of two Yompster products)
            or obtain from third parties to give you a more seamless, consistent and personalised experience, to make informed business decisions
            and for other legitimate purposes.
            </p>
            <br/>
            <h5>Reasons we share personal data</h5>
            <p>
            We share your personal data with your consent or to complete any transaction or provide any product you have requested or authorised.
            We also share data with Yompster-controlled affiliates and subsidiaries; with vendors working on our behalf; when required by law or
            to respond to legal process; to protect our customers; to protect lives; to maintain the security of our products; and to protect the
            rights and property of Yompster and its customers.
            </p>
          </Container>
          <Footer/>
        </>
      );
  }
}
