import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { API } from "aws-amplify";
import InfiniteList from "../components/InfiniteList";
import AvailableGroupListItem from "../components/AvailableGroupListItem";
import "./AvailableGroups.css";

export default class AvailableGroups extends Component {
  constructor(props) {
    super(props);

    this.handleJoinGroupClick = this.handleJoinGroupClick.bind(this);

    this.state = {
      stateBustingKey: 0
    };
  }

  joinGroup = (groupId) => {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {}
    }

    return API.post("groups", "/group/join?groupId=" + groupId.substring(1), request);
  }

  handleJoinGroupClick(groupId) {
    this.joinGroup(groupId).then(result => {
      this.setState({
        isLoading: false,
        groupSearchResults: [],
        stateBustingKey: this.state.stateBustingKey + 1
      });
      this.props.childprops.onRefreshProfile();
    });
  }

  render() {

    const childProps = {
      onJoinGroupClick: this.handleJoinGroupClick
    };

    return (
      <>
        <ListGroup className="tabContainer">
          <InfiniteList key={this.state.stateBustingKey} listComponent={AvailableGroupListItem} object={'groups'} route={'/groups/available'} childprops={childProps}/>
        </ListGroup>
      </>
    );
  }
}
