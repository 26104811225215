import React, { Component } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { API } from "aws-amplify";
import _ from "lodash";
import "./InfiniteList.css";

export default class InfiniteList extends Component {
  constructor(props) {
    super(props);

    // Sets up our initial state
    this.state = {
      error: false,
      hasMore: true,
      isLoading: false,
      offset: 0,
      size: this.props.pageSize || 10,
      feedItems: [],
      route: this.props.route,
      object: this.props.object || "",
      listComponent: this.props.listComponent || {},
      childProps: this.props.childprops
    };

    this.bindEventHandlers();
  }

  bindEventHandlers() {
       // Binds our scroll event handler
       window.onscroll = _.throttle(() => {
        const {
            error,
            isLoading,
            hasMore,
          } = this.state;

        if (error || isLoading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight) {
          this.loadFeedItems(this.state.offset, this.state.size);
        }
      }, 300)
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  componentWillMount() {
    this.loadFeedItems(this.state.offset, this.state.size);
  }

  feed(config) {
    return API.get(config.object, config.route + this.getOffsetString(config.offset, config.size));
  }

  getOffsetString(offset, size) {
    return "?offset=" + offset + "&size=" + size;
  }

  loadFeedItems() {
    const newOffset = this.state.offset + this.state.size;
    this.setState({isLoading: true}, () => {
      this.feed(
        {
          offset: this.state.offset,
          size: this.state.size,
          route: this.props.route,
          object: this.props.object
        }
      ).then((nextFeedItems) => {
        // Merges the next users into our existing users
        this.setState({
          // Note: Depending on the API you're using, this value may
          // be returned as part of the payload to indicate that there
          // is no additional data to be loaded
          hasMore: (this.state.feedItems.length < 100),
          isLoading: false,
          offset: newOffset,
          feedItems: this.state.feedItems.concat(nextFeedItems),
        });
      });
    });
  }

  render() {

    const {
      error,
      hasMore,
      isLoading,
      feedItems
    } = this.state;
    const ListComponent = this.props.listComponent;
    return (
      <Container className={'infinite-list'}>

        <ListGroup variant="flush">
          {feedItems.map((feedItem, i) => (
            <ListComponent listItemData={feedItem} {...this.state.childProps}/>
          ))}
        </ListGroup>

        {error &&
          <div style={{ color: '#900' }}>
            {error}
          </div>
        }
        {isLoading &&
          <>
            <span className="sr-only">Loading...</span>
          </>
        }
        {!hasMore &&
          <div>You did it! You reached the end!</div>
        }

      </Container>
    );
  }
}
