import React, { Component } from "react";
import { Card, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import "./NewEvent.css"

export default class NewEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: null,
      name: "",
      description: ""
    };
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
      event.preventDefault();

      this.setState({ isLoading: true });

      try {
        await this.createEvent({
          name: this.state.name,
          description: this.state.description,
          eventTime: "Test"
        });
        this.props.history.push("/");
      } catch (e) {
        alert(e);
        this.setState({ isLoading: false });
      }
    }

    createEvent(event) {
      return API.post("events", "/event", {
        body: event
      });
    }

  render() {
    return (
      <Card className="newEventCard">
        <Card.Header>Profile</Card.Header>
        <Card.Body>
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="name">
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
                componentclass="text"
              />
            </FormGroup>
            <FormGroup controlId="description">
              <FormControl
                onChange={this.handleChange}
                value={this.state.description}
                componentclass="textarea"
              />
            </FormGroup>
            <LoaderButton
              block
              bsstyle="primary"
              bssize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Create"
              loadingText="Creating…"
            />
          </form>
        </Card.Body>
      </Card>
    );
  }
}
