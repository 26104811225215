import React, { Component } from "react";
import { Row, Card, Col, Form, Button } from "react-bootstrap";
import config from "../config";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import "./User.css"

export default class User extends Component {
  constructor(props) {
    super(props);
    // Sets up our initial state
    this.state = {
      isLoading: false,
      user: {
        fullName: "Loading...",
        description: "",
        userId: ""
      }
    };
  }

  componentWillMount() {
    this.loadUser();
  }

  loadUser() {
    this.setState({isLoading: true}, () => {
      this.getUser().then((user) => {
        this.setState({
          user: user
        })
      });
    });
  }

  getUser() {
    return API.get("profiles", `/user/` + this.props.match.params.userId);
  }

  render() {
    return (
      <>
        <Row >
          <Col className="col-md-4 order-md-2 mb-4">
            <img src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <div class="fb-profile">
                <img align="left" class="fb-image-lg" src="http://lorempixel.com/850/280/nightlife/5/" alt="Profile image example"/>
                <Avatar className="fb-image-profile thumbnail" rounded userid={this.state.user.userId} currentprofile={this.state.user} avatarsize='medium' align="left"/>
                <div class="fb-profile-text">
                    <h1>{this.state.user.fullName}</h1>
                    <p>{this.state.user.description}</p>
                </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
