import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../containers/Footer.js"
import "./Press.css"

export default class Press extends Component {

  render() {
      return (
        <>
          <Container className="press-container">
            Stay tuned for updates on Yompster!
          </Container>
          <Footer/>
        </>
      );
  }
}
