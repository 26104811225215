import React, { Component } from 'react';
import { OverlayTrigger, Popover, Button, Col, Nav, Navbar, NavDropdown, Form, FormControl } from "react-bootstrap";
import Avatar from "../containers/Avatar";
import MessagesList from "../components/MessagesList";
import AlertsList from "../components/AlertsList";
import { API, Auth } from "aws-amplify";
import "./HeaderBar.css";

export default class HeaderBar extends Component {

  constructor(props) {
      super(props);
      this.state = {
        email: "",
        password: ""
      };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  navigateToProfile = event => {
    this.props.history.push("/profile");
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.props.onLogout();
  }

  handleLogin = async event => {
    event.preventDefault();
    try {
      Auth.signIn(this.state.email, this.state.password).then( () => {
        this.props.onRefreshProfile();
      });
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  getProfile() {
    return API.get("profiles", `/profile`);
  }

  render() {
    return (
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">Yompster</Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {this.props.currentProfile
              ?
              <>
                <Nav.Link>
                  <OverlayTrigger
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover id="messages-popover" title="Alerts">
                        <AlertsList/>
                      </Popover>
                    }
                  >
                    <i className="fa fa-bell messages-icon">
                      <span className="badge">2</span>
                    </i>
                  </OverlayTrigger>
                </Nav.Link>
                <Nav.Link>
                  <OverlayTrigger
                    trigger="click"
                    key="bottom"
                    placement="bottom"
                    rootClose={true}
                    overlay={
                      <Popover id="messages-popover" title="Messages">
                        <MessagesList/>
                      </Popover>
                    }
                  >
                    <i className="fa fa-envelope messages-icon">
                      <span className="badge badge-danger">5</span>
                    </i>
                  </OverlayTrigger>
                </Nav.Link>
                <Avatar avatarsize="small" rounded currentprofile={this.props.currentProfile}/>
                <NavDropdown title={this.props.currentProfile.fullName} id="basic-nav-dropdown">
                  <NavDropdown.Item onSelect={this.handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
                </>
              :
                <Form onSubmit={this.handleLogin} inline>
                  <Form.Row>
                    <Col>
                      <FormControl name="email" type="text" onChange={this.handleChange} placeholder="Email address"/>
                    </Col>
                    <Col>
                      <FormControl name="password" type="password" onChange={this.handleChange} placeholder="Password"/>
                    </Col>
                    <Col>
                      <Button disabled={!this.validateForm()} type="submit">Login</Button>
                    </Col>
                  </Form.Row>
                </Form>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
