import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import { API } from "aws-amplify";
import InfiniteList from "../components/InfiniteList";
import GroupsListItem from "../components/GroupsListItem";
import "./MyGroups.css";

export default class MyGroups extends Component {
  constructor(props) {
    super(props);

    this.handleLeaveGroupClick = this.handleLeaveGroupClick.bind(this);

    this.state = {
      stateBustingKey: 0
    };
  }

  leaveGroup = (groupId) => {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {}
    }
    return API.del("groups", "/groups/leave/" + groupId.substring(1), request);
  }

  handleLeaveGroupClick(groupId) {
    this.setState({isLoading: true}, () => {
      console.log("Leaving group: " + groupId);
      this.leaveGroup(groupId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
        this.props.childprops.onRefreshProfile();
      });
    });
  }

  render() {

    const childProps = {
      onLeaveGroupClick: this.handleLeaveGroupClick
    };

    return (
      <>
        <div className="tabContainer">
          <InfiniteList key={this.state.stateBustingKey} listComponent={GroupsListItem} object={'groups'} route={'/groups'} childprops={childProps}/>
        </div>
      </>
    );
  }
}
