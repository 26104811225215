import React, { Component, Link } from "react";
import { ListGroup, InputGroup, Tabs, Tab, Modal, Button, Container, Card, Row, Col, Form, FormGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import InfiniteList from "../components/InfiniteList";
import GroupsListItem from "../components/GroupsListItem";
import LoaderButton from "../components/LoaderButton";
import MyGroups from "../components/MyGroups";
import AvailableGroups from "../components/AvailableGroups";
import "./Groups.css";

export default class Groups extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitCreateGroup = this.handleSubmitCreateGroup.bind(this);
    this.handleCreateGroupsClose = this.handleCreateGroupsClose.bind(this);
    this.handleGroupsClose = this.handleGroupsClose.bind(this);

    this.state = {
      stateBustingKey: 0,
      isLoading: false,
      showCreateGroup: false,
      groupSearchResults: [],
      groupsQuery: "",
      createGroupName: "",
      createGroupDescription: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  validateCreateGroupForm() {
    return (
      this.state.createGroupName.length > 0 &&
      this.state.createGroupDescription.length > 0
    );
  }

  validateGroupSearchForm = () => {
    return this.state.groupsQuery.length > 0;
  }

  groups = (offset, size, query) => {
    return API.get("groups", "/groups/find?offset=0&size=25&q=" + query);
  }

  createGroup() {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {
        "name": this.state.createGroupName,
        "description": this.state.createGroupDescription
      }
    }

    return API.post("groups", "/group", request);
  }

  joinGroup = (groupId) => {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {}
    }

    return API.post("groups", "/group/join?groupId=" + groupId.substring(1), request);
  }

  handleChangeCreateGroup = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmitCreateGroup() {
    this.createGroup()
      .then((response) => {
        this.setState({
          showCreateGroup: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
        this.props.onRefreshProfile();
      });
  }

  handleSearchGroups = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, profiles: [] });

    try {
      this.groups(0, 25, this.state.groupsQuery)
        .then((nextGroupItems) => {
            this.setState({
              isLoading: false,
              groupSearchResults: nextGroupItems,
            });
        });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleCreateGroup = async event => {
    this.setState({ showCreateGroup: true });
  }

  handleGroupsClose() {
    this.setState({ groupSearchResults: [], groupsQuery: "" });
  }

  handleCreateGroupsClose() {
    this.setState({ showCreateGroup: false });
  }

  handleJoinGroupClick(groupId) {
    this.joinGroup(groupId).then(result => {
      this.setState({
        isLoading: false,
        groupSearchResults: [],
        stateBustingKey: this.state.stateBustingKey + 1
      });
      this.props.onRefreshProfile();
    });
  }

  render() {

    const childProps = {
      currentDate: new Date(),
      onRefreshProfile: this.props.onRefreshProfile
    };

    return (
      <>
        <Row>
          <Col className="col-md-4 order-md-2 mb-4">
            <Card className="groupsActionsCard">
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="actionMenuItem">
                    <span class="createGroupLabel">
                      <i class="fas fa-comments"></i> <a onClick={event => {this.handleCreateGroup()}}>Create group</a>
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <Card className="groupsActionsCard">
              <Card.Body>
                <Form inline onSubmit={this.handleSearchGroups}>
                  <Form.Row>
                    <Col>
                      <FormGroup controlId="groupsQuery">
                        <FormControl
                          name="search"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.groupsQuery}
                          placeholder="Find groups"/>
                       </FormGroup>
                    </Col>
                    <Col>
                      <Button type="submit" disabled={!this.validateGroupSearchForm()}>Search</Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Card.Body>
            </Card>
            <img className="d-none d-sm-block" src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <Tabs defaultActiveKey="myGroups" className="groupsTab">
              <Tab eventKey="myGroups" title="My Groups">
                <MyGroups childprops={childProps} />
              </Tab>
              <Tab eventKey="availableGroups" title="Available Groups">
                <AvailableGroups childprops={childProps} />
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Modal show={this.state.showCreateGroup} onHide={this.handleCreateGroupsClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmitCreateGroup}>
              <Form.Group controlId="createGroupName">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control type="text" onChange={this.handleChangeCreateGroup}/>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="createGroupDescription">
                <Form.Label>Description</Form.Label>
                <InputGroup>
                  <Form.Control type="text" onChange={this.handleChangeCreateGroup}/>
                </InputGroup>
              </Form.Group>

              <LoaderButton
                bsstyle="primary"
                bssize="large"
                className="text-center btn-block"
                disabled={!this.validateCreateGroupForm()}
                type="submit"
                text="Create Group"
                loadingText="Saving…"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCreateGroupsClose}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.groupSearchResults.length > 0} onHide={this.handleGroupsClose}>
          <Modal.Header closeButton>
            <Modal.Title>Groups</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Results for: {this.state.groupsQuery}</div>
            {this.state.groupSearchResults.map((group, i) => (
            <ListGroup>
              <ListGroup.Item>
                <Row>
                  <Col>
                    <div className="media">
                      <div className="media-body groupText">
                        {group.name}<br/>
                        {group.description}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Button variant="primary" onClick={() => this.handleJoinGroupClick(group.dbId)}>Join</Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleGroupsClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
