import React, { Component } from "react";
import './Avatar.css';
import { Storage } from "aws-amplify";
import { Image } from "react-bootstrap";

export default class Avatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
          imgUrl: "./default_avatar_64x64.png",
          avatarsize: props.avatarsize || 'medium',
          profilePicKey: props.currentprofile.profilePicKey || null
        };
    }

    async componentDidUpdate() {
      if(this.state.profilePicKey != this.props.currentprofile.profilePicKey) {
        this.getImgUrl(this.props.currentprofile).then(imgUrl => {
          this.setState({
            imgUrl: imgUrl,
            profilePicKey: this.props.currentprofile.profilePicKey
          });
        });
      }
    }

    async componentDidMount() {
      this.getImgUrl(this.props.currentprofile).then(imgUrl => {
        this.setState({
          imgUrl: imgUrl
        });
      });
    }

    async getImgUrl(profile) {
      try {
        if(profile && profile.profilePicKey) {
          let imgUrl = await Storage.get(profile.profilePicKey);
          return imgUrl;
        }
      } catch (e) {
        console.error(e);
      }

      return "./default_avatar_64x64.png";
    }

    render() {
        const { ...rest } = this.props;
        return (
            <>
                <Image src={this.state.imgUrl} className={'avatar-' + this.state.avatarsize} alt="profile image" {...rest}/>
            </>
        );
    }
}
