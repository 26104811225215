import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../containers/Footer.js"
import "./AboutUs.css"

export default class AboutUs extends Component {

  render() {
      return (
        <>
          <Container className="about-container">
            <p className="about-para">
            Some legends say they were a group of techno-anarchists trying to overthrow the establishment through social reunification...
            </p>
            <p className="about-para">
            Other speaks of a shadowy group who splittered away from the original MKULTRA project because it wasn't extreme enough...
            </p>
            <p className="about-para">
            One person who claims to have been present at the inception of Yompster says it was just a prank instigated by a handlful of burnt out potheads...
            </p>
            <p className="about-para">
            <b>In the end we may never know... And maybe that's for the best.</b>
            </p>
            <br/>
            <p className="about-para">
            What we do know is that Yompster turns the entire way we think about Social Media on it's head. No longer will online interactions replace real life connections. Now, with Yompster, socal media can finally be used to bring people together for real life interactions.
            </p>
          </Container>
          <Footer/>
        </>
      );
  }
}
