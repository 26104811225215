import React, { Component } from "react";
import { Container, Row, Col} from "react-bootstrap";
import { Form } from 'react-bootstrap';
import { API } from "aws-amplify";
import _ from "lodash";
import "./InfiniteCardList.css";

export default class InfiniteList extends Component {
  constructor(props) {
    super(props);

    // Sets up our initial state
    this.state = {
      error: false,
      showPageSize: this.props.showPageSize,
      hasMore: true,
      isLoading: false,
      offset: 0,
      size: this.props.pageSize || 10,
      feedItems: [],
      route: this.props.route,
      object: this.props.object || "",
      listComponent: this.props.listComponent || {},
      childProps: this.props.childprops
    };

    this.bindEventHandlers();
  }

  bindEventHandlers() {
       // Binds our scroll event handler
       window.onscroll = _.throttle(() => {
        const {
            error,
            isLoading,
            hasMore,
          } = this.state;

        if (error || isLoading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop
              === document.documentElement.offsetHeight) {
          this.loadFeedItems(this.state.offset, this.state.size);
        }
      }, 300)
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  componentWillMount() {
    this.loadFeedItems(this.state.offset, this.state.size);
  }

  feed(config) {
    return API.get(config.object, config.route + this.getOffsetString(config.offset, config.size));
  }

  getOffsetString(offset, size) {
    return "?offset=" + offset + "&size=" + size;
  }

  loadFeedItems() {
    const newOffset = this.state.offset + this.state.size;
    this.setState({isLoading: true}, () => {
      this.feed(
        {
          offset: this.state.offset,
          size: this.state.size,
          route: this.props.route,
          object: this.props.object
        }
      ).then((nextFeedItems) => {
        // Merges the next users into our existing users
        this.setState({
          // Note: Depending on the API you're using, this value may
          // be returned as part of the payload to indicate that there
          // is no additional data to be loaded
          hasMore: (this.state.feedItems.length < 100),
          isLoading: false,
          offset: newOffset,
          feedItems: this.state.feedItems.concat(nextFeedItems),
        });
      });
    });
  }

  render() {

    console.log("InfiniteList render()");

    const {
      error,
      hasMore,
      isLoading,
      feedItems
    } = this.state;
    const ListComponent = this.props.listComponent;
    return (
      <Container className={'infinite-list'}>
        {this.state.showPageSize &&
        <Row>
          <Col>
            <div className={'controls'}>
              <Form inline>
                <Form.Label className={'form-label'}>Page Size</Form.Label>
                <Form.Control as="select" onChange={(evt) => {this.setState({size: parseInt(evt.target.value)});}}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </Form.Control>
              </Form>
            </div>
          </Col>
        </Row>
        }
        <Row>
          {feedItems.map((feedItem, i) => (
            <Col key={'listFeedItem' + i}>
              <div>
                <ListComponent listItemData={feedItem} {...this.state.childProps}/>
              </div>
            </Col>
          ))}
          <hr />
          {error &&
            <div style={{ color: '#900' }}>
              {error}
            </div>
          }
          {isLoading &&
            <>
              <span className="sr-only">Loading...</span>
            </>
          }
          {!hasMore &&
            <div>You did it! You reached the end!</div>
          }
        </Row>
      </Container>
    );
  }
}
