import React, { Component }  from "react";
import { Card, Dropdown, ListGroup, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./GroupsListItem.css"

export default class GroupsListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onLeaveGroupClick: this.props.onLeaveGroupClick,
      listItemData: this.props.listItemData || {}
    };
  }

  render() {
    const {
      listItemData
    } = this.state;
    return(
      <>
        <ListGroup.Item className="groupRow">
        <Row>
          <div class="media col-md-3">
                  <figure class="pull-left">
                      <img class="media-object img-rounded img-responsive"  src="https://picsum.photos/100/100" alt="placehold.it/100x100" ></img>
                  </figure>
              </div>
              <div class="col-md-6">
                  <h4 class="list-group-item-heading"> <LinkContainer className="groupName" to={'/Group/' + listItemData.dbId.substring(1)}>
                    <a>{listItemData.name}</a>
                  </LinkContainer> </h4>
                  <p class="list-group-item-text"> {listItemData.description}
                  </p>
              </div>
              <div class="col-md-3 text-center">
                <Dropdown>
                  <Dropdown.Toggle className="btn btn-flat btn-flat-icon float-right dropdown-toggle">
                    <i className="fa fa-ellipsis-h"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => this.state.onLeaveGroupClick(listItemData.dbId)}>Leave</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="groupMemberCount">{listItemData.memberCount} members</div>
              </div>
            </Row>


        </ListGroup.Item>
      </>
    );
  }
}
