import React, { Component } from 'react';
import { ListGroup } from "react-bootstrap";
import Avatar from "../containers/Avatar";
import "./AlertsList.css";

export default class AlertsList extends Component {

  constructor(props) {
      super(props);
  }

  render() {
    return (
      <ListGroup>
        <ListGroup.Item>Cras justo odio</ListGroup.Item>
        <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
      </ListGroup>
    );
  }
}
