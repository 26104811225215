import React, { Component }  from "react";
import { Form, InputGroup, Row, Col, Card, Badge } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import * as moment from 'moment'
import "./CommentListItem.css"

export default class CommentListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listItemData: this.props.listItemData || {}
    };
  }

  render() {
    const {
      listItemData
    } = this.state;
    return(
      <Row className=" comment-row">
        <Col className="thumbnail-column">
          <Avatar rounded currentprofile={listItemData.author} avatarsize='micro'/>
        </Col>
        <Col className="d-flex justify-content-start comment-column">
          <div className="comment-content">
            {listItemData.content}
          </div>
          <div className="h7 text-muted comment-date">
              {moment(listItemData.createdDate).fromNow()}
          </div>
        </Col>
      </Row>
    );
  }
}
