import React, { Component } from "react";
import { Tabs, Tab, ListGroup, InputGroup, Modal, Row, Card, Col, Form, Button } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { API } from "aws-amplify";
import InfiniteList from "../components/InfiniteList";
import FeedListItem from "../components/FeedListItem";
import DayPicker from 'react-day-picker';
import * as moment from 'moment'
import 'react-day-picker/lib/style.css';
import "./Group.css"

export default class Group extends Component {
  constructor(props) {
    super(props);

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleSubmitNewActivity = this.handleSubmitNewActivity.bind(this);
    this.handleNewActivityClose = this.handleNewActivityClose.bind(this);
    this.handleLikeClick = this.handleLikeClick.bind(this);
    this.handleUnlikeClick = this.handleUnlikeClick.bind(this);

    // Sets up our initial state
    this.state = {
      selectedDay: undefined,
      stateBustingKey: 0,
      isLoading: false,
      createActivityName: "",
      createActivityDescription: "",
      group: {
        name: "Loading...",
        description: "",
        memberCount: 0,
        showNewActivity: false
      }
    };
  }

  componentWillMount() {
    this.loadGroup();
  }

  loadGroup() {
    this.setState({isLoading: true}, () => {
      this.getGroup().then((group) => {
        this.setState({
          group: group[0]
        })
      });
    });
  }

  createActivity(groupId) {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {
        "name": this.state.createActivityName,
        "description": this.state.createActivityDescription,
        "activityDate": moment(this.state.selectedDay).format('YYYY-MM-D HH:mm:ss'),
        "groupId": groupId
      }
    }

    return API.post("groups", "/activity", request);
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmitNewActivity() {
    this.createActivity(this.props.match.params.groupId)
      .then((response) => {
        this.setState({
          showNewActivity: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
        this.props.onRefreshProfile();
      });
  }

  handleNewActivityClose() {
    this.setState({ showNewActivity: false });
  }

  validateNewActivityForm() {
    return (
      this.state.createActivityName.length > 0 &&
      this.state.createActivityDescription.length > 0
    );
  }

  getGroup() {
    return API.get("profiles", `/group/` + this.props.match.params.groupId);
  }

  likeEvent(event) {
    return API.post("groups", "/activity/" + event.substring(1) + "/like");
  }

  unlikeEvent(event) {
    return API.post("groups", "/activity/" + event.substring(1) + "/unlike");
  }

  handleLikeClick(activityId) {
    this.setState({isLoading: true}, () => {
      this.likeEvent(activityId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
      });
    });
  }

  handleUnlikeClick(activityId) {
    this.setState({isLoading: true}, () => {
      this.unlikeEvent(activityId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
      });
    });
  }

  handleDayClick(day) {
    this.setState({ selectedDay: day });
  }

  render() {

    const childProps = {
      onLikeClick: this.handleLikeClick,
      onUnlikeClick: this.handleUnlikeClick,
      currentProfile: this.props.currentProfile
    };

    return (
      <>
        <Row >
          <Col className="col-md-4 order-md-2 mb-4">
            <Card className="groupActionsCard">
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="actionMenuItem">
                    <span class="createActivityLabel">
                      <i class="fas fa-comments"></i> <a onClick={event => {this.setState({showNewActivity: true})}}>New Activity</a>
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <img src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <Card className="groupCard">
              <Card.Title>
                <div className="groupName">{this.state.group.name}</div>
              </Card.Title>
              <Card.Body>
                <div className="groupDescription">{this.state.group.description}</div>
                <div className="groupMemberCount">{this.state.group.memberCount} members</div>
              </Card.Body>
            </Card>
            <InfiniteList className="activities-list" key={this.state.stateBustingKey} listComponent={FeedListItem} object={'groups'} route={'/group/feed/' + this.props.match.params.groupId} childprops={childProps}/>
          </Col>
        </Row>
        <Modal show={this.state.showNewActivity} onHide={this.handleNewActivityClose}>
          <Modal.Header closeButton>
            <Modal.Title>New Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmitNewActivity}>
              <Form.Group controlId="createActivityName">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <Form.Control type="text" onChange={this.handleChange}/>
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="createActivityDescription">
                <Form.Label>Description</Form.Label>
                <InputGroup>
                  <Form.Control type="text" onChange={this.handleChange}/>
                </InputGroup>
              </Form.Group>

              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                <Tab eventKey="future" title="Future">
                  <DayPicker
                    todayButton="Today"
                    onDayClick={this.handleDayClick}
                    selectedDays={this.state.selectedDay}
                    onTodayButtonClick={(day, modifiers) => console.log(day, modifiers)}/>
                </Tab>
                <Tab eventKey="recurring" title="Recurring">
                  <p>Test</p>
                </Tab>
                <Tab eventKey="instant" title="Right Now">
                  <p>Another Test</p>
                </Tab>
              </Tabs>



              {this.state.selectedDay ? (
                <p>You clicked {this.state.selectedDay.toLocaleDateString()}</p>
              ) : (
                <p>Please select a day.</p>
              )}

              <LoaderButton
                bsstyle="primary"
                bssize="large"
                className="text-center btn-block"
                disabled={!this.validateNewActivityForm()}
                type="submit"
                text="Save"
                loadingText="Saving…"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleNewActivityClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
