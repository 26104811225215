import React, { Component } from "react";
import { ListGroup, Badge, Row, Col, Card, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import FeedListItem from "../components/FeedListItem";
import InfiniteList from "../components/InfiniteList";
import { API } from "aws-amplify";
import "./Feed.css";

export default class Feed extends Component {
  constructor(props) {
    super(props);

    this.handleLikeClick = this.handleLikeClick.bind(this);
    this.handleUnlikeClick = this.handleUnlikeClick.bind(this);

    // Sets up our initial state
    this.state = {
      error: false,
      hasMore: true,
      isLoading: false,
      offset: 0,
      size: 10,
      name: "",
      description: ""
    };
  }

  likeEvent(event) {
    return API.post("groups", "/activity/" + event.substring(1) + "/like");
  }

  unlikeEvent(event) {
    return API.post("groups", "/activity/" + event.substring(1) + "/unlike");
  }

  handleLikeClick(activityId) {
    this.setState({isLoading: true}, () => {
      this.likeEvent(activityId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
      });
    });
  }

  handleUnlikeClick(activityId) {
    this.setState({isLoading: true}, () => {
      this.unlikeEvent(activityId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
      });
    });
  }

  render() {

    const childProps = {
      onLikeClick: this.handleLikeClick,
      onUnlikeClick: this.handleUnlikeClick,
      currentProfile: this.props.currentProfile
    };

    return (
      <>
        <Row >
          <Col className="col-md-4 order-md-2 mb-4">
            <Card className="feedActionsCard">
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="actionMenuItem">
                    <span class="createActivityLabel">
                      <i class="fas fa-comments"></i> <a onClick={event => {this.setState({showNewActivity: true})}}>New Activity</a>
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <img className="d-none d-sm-block" src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <InfiniteList listComponent={FeedListItem} key={this.state.stateBustingKey} object={'profiles'} route={'/feed'} childprops={childProps}/>
          </Col>
        </Row>
      </>
    );
  }
}
