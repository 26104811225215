import React, { Component } from "react";
import { ListGroup, Modal, Row, Card, Col, Form, Button } from "react-bootstrap";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import EditProfile from "../containers/EditProfile"
import "./User.css"

export default class User extends Component {
  constructor(props) {
    super(props);

    this.handleEditProfile = this.handleEditProfile.bind(this);
    this.handleEditProfileClose = this.handleEditProfileClose.bind(this);

    // Sets up our initial state
    this.state = {
      isLoading: false,
      showEdit: false,
      profile: {
        fullName: "Loading...",
        description: "",
        userId: ""
      }
    };
  }

  componentWillMount() {
    this.loadProfile();
  }

  loadProfile() {
    this.setState({isLoading: true}, () => {
      this.getProfile().then((profile) => {
        this.setState({
          profile: profile
        })
      });
    });
  }

   handleEditProfile() {
     this.setState({ showEdit: true });
   }

   handleEditProfileClose() {
     this.setState({ showEdit: false });
   }

  getProfile() {
    return API.get("profiles", `/profile`);
  }

  render() {

    const childProps = {
      onRefreshProfile: this.props.onRefreshProfile
    };

    return (
      <>
        <Row >
          <Col className="col-md-4 order-md-2 mb-4">
          <Card className="profileActionsCard">
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item className="actionMenuItem">
                  <span class="editProfileLabel">
                    <i class="fas fa-comments"></i> <a onClick={event => {this.handleEditProfile()}}>Edit profile</a>
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
            <img src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <div class="fb-profile">
                <img align="left" class="fb-image-lg" src="http://lorempixel.com/850/280/nightlife/5/" alt="Profile image example"/>
                <Avatar className="fb-image-profile thumbnail" rounded currentprofile={this.state.profile} avatarsize='medium' align="left"/>
                <div class="fb-profile-text">
                    <h1>{this.state.profile.fullName}</h1>
                    <p>{this.state.profile.description}</p>
                </div>
            </div>
          </Col>
        </Row>
        <Modal show={this.state.showEdit} onHide={this.handleEditProfileClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditProfile profile={this.state.profile} childProps={childProps}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleEditProfileClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
