import React, { Component }  from "react";
import { Dropdown, Card, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Avatar from "../containers/Avatar";
import "./FriendsListItem.css"

export default class FriendsListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onRemoveFriendClick: this.props.onRemoveFriendClick,
      listItemData: this.props.listItemData || {}
    };
  }

  render() {
    const {
      listItemData
    } = this.state;
    return(
      <>
        <Card className="friendCard">
          <Card.Body>

            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-2">
                        <Avatar rounded userid={listItemData.friendId} currentprofile={listItemData} avatarsize='small'/>
                    </div>
                    <div className="ml-2">
                        <div className="h5 m-0 friendName">
                          <LinkContainer className="groupName" to={'/User/' + listItemData.dbId.substring(1)} >
                            <a>{listItemData.fullName}</a>
                          </LinkContainer>
                        </div>
                        <div className="h7 text-muted">{listItemData.description}</div>
                    </div>
                </div>
                <div>
                  <Dropdown>
                    <Dropdown.Toggle className="btn btn-flat btn-flat-icon float-right dropdown-toggle">
                      <i className="fa fa-ellipsis-h"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => this.state.onRemoveFriendClick(listItemData.dbId)}>Remove</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
            </div>

          </Card.Body>
        </Card>
      </>
    );
  }
}
