import React, { Component } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import Routes from "./Routes";
import UnauthedRoutes from "./UnauthedRoutes";
import HeaderBar from "./components/HeaderBar"
import "./App.css";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
          isAuthenticated: false,
          currentProfile: null
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.refreshProfile = this.refreshProfile.bind(this);
    }

    async componentDidMount() {
      Auth.currentAuthenticatedUser().then( user => {
        try {
          this.getProfile().then( profile => {
            this.setState({currentProfile: profile});
          });
        }
        catch (e) {
          if (e !== "not authenticated") {
              alert(e);
          }
        }
      });
    }

    handleLogout() {
      this.setState({currentProfile: null});
    }

    refreshProfile() {
      try {
        console.log("Refreshing profile");
        this.getProfile().then(profile => {
          this.setState({currentProfile: profile});
        });
      } catch (e) {
        alert(e.message);
        this.setState({ isLoading: false });
      }
    }

    getProfile() {
      return API.get("profiles", `/profile`);
    }

    render() {
      console.log("App render");
        const childProps = {
          currentProfile: this.state.currentProfile,
          onRefreshProfile: this.refreshProfile
        };

        if(this.state.currentProfile) {
          return (<>
            <HeaderBar
              currentProfile={this.state.currentProfile}
              onRefreshProfile={this.refreshProfile}
              onLogout={this.handleLogout}/>

              <Row className="justify-content-center">
                <Col className="col-md-2 order-md-1 menuColumn">
                  <ListGroup>
                  <LinkContainer to="/Feed">
                    <ListGroup.Item><i className="fas fa-broadcast-tower"></i> Feed</ListGroup.Item>
                  </LinkContainer>
                    <LinkContainer to="/Friends">
                      <ListGroup.Item><i className="fas fa-users"></i> Friends<span>{this.state.currentProfile.friendCount}</span></ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to="/Groups">
                      <ListGroup.Item><i className="fas fa-comments"></i> Groups<span>{this.state.currentProfile.memberCount}</span></ListGroup.Item>
                    </LinkContainer>
                    <LinkContainer to="/Profile">
                      <ListGroup.Item><i className="fas fa-user-edit"></i> Profile</ListGroup.Item>
                    </LinkContainer>
                  </ListGroup>
                </Col>
                <Col className="col-md-10 order-md-2 contentColumn">
                  <Routes childProps={childProps}/>
                </Col>
              </Row>
          </>);
        }
        else {
          return (
            <>
              <HeaderBar
                onRefreshProfile={this.refreshProfile}
                onLogout={this.handleLogout}/>
              <UnauthedRoutes childProps={childProps}/>
            </>);
        }


    }
}

export default withRouter(App);
