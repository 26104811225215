import React, { Component } from "react";
import { ListGroup, Modal, Button, Card, Row, Col, Form, FormGroup, FormControl } from "react-bootstrap";
import { API } from "aws-amplify";
import Avatar from "../containers/Avatar";
import InfiniteCardList from "../components/InfiniteCardList";
import FriendsListItem from "../components/FriendsListItem";
import "./Friends.css";

export default class Friends extends Component {
  constructor(props) {
    super(props);

    this.handleProfilesClose = this.handleProfilesClose.bind(this);
    this.handleAddFriendClick = this.handleAddFriendClick.bind(this);
    this.handleRemoveFriendClick = this.handleRemoveFriendClick.bind(this);

    this.state = {
      stateBustingKey: 0,
      isLoading: false,
      profileSearchResults: [],
      profilesQuery: ""
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  validateProfileSearchForm = () => {
    return this.state.profilesQuery.length > 0;
  }

  profiles = (offset, size, query) => {
    return API.get("profiles", "/profiles?offset=0&size=25&q=" + query);
  }

  addFriend = (friendId) => {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {}
    }

    return API.post("profiles", "/profile/friend?friendId=" + friendId.substring(1), request);
  }

  removeFriend = (friendId) => {
    let request = {
      headers: {'Content-Type': 'application/json'},
      body: {}
    }
    return API.del("profiles", "/profile/friend/" + friendId.substring(1), request);
  }

  handleSearchProfiles = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, profiles: [] });

    try {
      this.profiles(0, 25, this.state.profilesQuery)
        .then((nextProfileItems) => {
            this.setState({
              isLoading: false,
              profileSearchResults: nextProfileItems,
            });
        });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  handleAddFriendClick(friendId) {
    console.log("handleAddFriendClick()");
    this.addFriend(friendId).then(result => {
      this.setState({
        isLoading: false,
        profileSearchResults: [],
        stateBustingKey: this.state.stateBustingKey + 1
      });
      this.props.onRefreshProfile();
    });
  }

  handleRemoveFriendClick(friendId) {
    this.setState({isLoading: true}, () => {
      console.log("Removing friend: " + friendId);
      this.removeFriend(friendId).then(result => {
        this.setState({
          isLoading: false,
          stateBustingKey: this.state.stateBustingKey + 1
        });
        this.props.onRefreshProfile();
      });
    });
  }

  handleProfilesClose() {
    this.setState({ profileSearchResults: [], profilesQuery: "" });
  }

  render() {

    console.log("Friends render()");

    const childProps = {
      onRemoveFriendClick: this.handleRemoveFriendClick,
      currentDate: new Date()
    };

    return (
      <>
        <Row>
          <Col className="col-md-4 order-md-2 mb-4">
            <Card className="friendsActionsCard">
              <Card.Body>
                <Form inline onSubmit={this.handleSearchProfiles}>
                  <Form.Row>
                    <Col>
                      <FormGroup controlId="profilesQuery">
                        <FormControl
                          name="search"
                          type="text"
                          onChange={this.handleChange}
                          value={this.state.profilesQuery}
                          placeholder="Find friends"/>
                       </FormGroup>
                    </Col>
                    <Col>
                      <Button type="submit" disabled={!this.validateProfileSearchForm()}>Search</Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Card.Body>
            </Card>
            <img className="d-none d-sm-block" src="https://via.placeholder.com/350x400.png?text=Ads%20Here" alt="Ads area"></img>
          </Col>
          <Col className="col-md-8 order-md-1">
            <ListGroup>
              <InfiniteCardList key={this.state.stateBustingKey} listComponent={FriendsListItem} object={'friends'} route={'/friends'} childprops={childProps}/>
            </ListGroup>
          </Col>
        </Row>
        <Modal show={this.state.profileSearchResults.length > 0} onHide={this.handleProfilesClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Friends</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Results for: {this.state.profilesQuery}</div>
            {this.state.profileSearchResults.map((profile, i) => (
            <Card className="profileCard" key={'profile' + i}>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="media">
                      <Avatar rounded currentprofile={profile} avatarsize='small'/>
                      <div className="media-body friendText">
                        {profile.fullName}<br/>
                        {profile.description}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Button variant="primary" onClick={() => this.handleAddFriendClick(profile.dbId)}>Add Friend</Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleProfilesClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
