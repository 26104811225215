import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import AboutUs from "./containers/AboutUs";
import Careers from "./containers/Careers";
import Press from "./containers/Press";
import Privacy from "./containers/Privacy";
import AboutApi from "./containers/AboutApi";

import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <UnauthenticatedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/about" exact component={AboutUs} props={childProps} />
    <UnauthenticatedRoute path="/careers" exact component={Careers} props={childProps} />
    <UnauthenticatedRoute path="/press" exact component={Press} props={childProps} />
    <UnauthenticatedRoute path="/privacy" exact component={Privacy} props={childProps} />
    <UnauthenticatedRoute path="/api" exact component={AboutApi} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={Home} />
  </Switch>;
