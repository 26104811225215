import React, { Component }  from "react";
import { ListGroup, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./AvailableGroupListItem.css"

export default class AvailableGroupListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onJoinGroupClick: this.props.onJoinGroupClick,
      listItemData: this.props.listItemData || {}
    };
  }

  render() {
    const {
      listItemData
    } = this.state;
    return(
      <>
      <ListGroup.Item className="groupRow">
        <Row>
          <div class="media col-md-3">
              <figure class="pull-left">
                  <img class="media-object img-rounded img-responsive"  src="https://picsum.photos/150/150" alt="placehold.it/350x250" ></img>
              </figure>
          </div>
          <div class="col-md-6">
              <h4 class="list-group-item-heading"> <LinkContainer className="groupName" to={'/Group/' + listItemData.dbId.substring(1)}>
                <a>{listItemData.name}</a>
              </LinkContainer> </h4>
              <p class="list-group-item-text"> {listItemData.description}
              </p>
          </div>
          <div class="col-md-3 text-center">
            <Button className="float-right" onClick={() => this.state.onJoinGroupClick(listItemData.dbId)} variant="secondary">Join</Button>
            <div className="groupMemberCount">{listItemData.memberCount} members</div>
          </div>
        </Row>
      </ListGroup.Item>
      </>
    );
  }
}
