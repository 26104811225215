import React, { Component } from "react";
import { InputGroup, Container, Form, FormGroup, FormControl } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./SignupPanel.css";

export default class SignupPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      fullName: "",
      description: null,
      email: "",
      password: "",
      confirmationCode: "",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.fullName.length > 0 &&
      this.state.password.length > 0
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  createProfile(profile) {
    return API.post("profiles", "/profile", {
      body: profile
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password
      });
      this.setState({
        newUser
      });
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      await Auth.signIn(this.state.email, this.state.password);

      await this.createProfile({
        fullName: this.state.fullName,
        description: this.state.description,
        email: this.state.email
      });

      this.props.onRefreshProfile();

    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <Form.Label>Confirmation Code</Form.Label>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderSignupForm() {
    return (
      <Container className="signupPanel">
        <h4 className="card-title mt-3 text-center">Create Account</h4>
        <p className="text-center">Get started with your free account</p>
        <p>
      		<a href="/" className="btn btn-block btn-twitter"> <i className="fab fa-twitter"></i>   Login via Twitter</a>
      		<a href="/" className="btn btn-block btn-facebook"> <i className="fab fa-facebook-f"></i>   Login via facebook</a>
      	</p>
        <p className="divider-text">
          <span className="bg-light">OR</span>
        </p>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="fullName">
            <Form.Label>Full name</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-user"></i></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="text" onChange={this.handleChange}/>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope"></i></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="text" onChange={this.handleChange}/>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-lock"></i></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="password" onChange={this.handleChange}/>
            </InputGroup>
          </Form.Group>

          <LoaderButton
            bsstyle="primary"
            bssize="large"
            className="text-center btn-block"
            disabled={!this.validateForm()}
            type="submit"
            text="Create Account"
            loadingText="Saving…"
          />
        </Form>

        <p className="text-center">Have an account? <a href="/">Log In</a> </p>
      </Container>
    );
  }

  render() {
    return (
      <Container>
        {this.state.newUser === null
          ? this.renderSignupForm()
          : this.renderConfirmationForm()}
      </Container>
    );
  }
}
