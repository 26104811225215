import React, { Component } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import config from "../config";
import Avatar from "../containers/Avatar";
import LoaderButton from "../components/LoaderButton";
import { API, Storage } from "aws-amplify";
import { s3Upload } from "../libs/awsLib";
import "./Profile.css"

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      isLoading: true,
      profile: this.props.profile,
      fullName: "",
      description: ""
    };
  }

  async componentDidMount() {
    try {
      if (!this.props.profile) {
        return;
      }

      let profilePicUrl;
      if (this.props.profile.profilePicKey) {
        profilePicUrl = await Storage.get(this.props.profile.profilePicKey);
      }

      this.setState({
        isLoading: false,
        profile: this.props.profile,
        fullName: this.props.profile.fullName,
        description: this.props.profile.description,
        profilePicUrl: profilePicUrl,
        profilePicKey: this.props.profile.profilePicKey
      });
    } catch (e) {
      alert(e);
    }
  }

  validateForm() {
    return (
      this.state.fullName.length > 0
    );
  }

  saveProfile(profile) {
    return API.put("profiles", `/profile`, {
      body: profile
    });
  }

  handleProfilePicFileChange = event => {
    this.file = event.target.files[0];
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    let profilePicKey;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    try {
      this.setState({ isLoading: true });

      if (this.file) {
        const filename = `${this.props.profile.userId}` + "_" + new Date().getTime() ;
        profilePicKey = await s3Upload(this.file, filename);
      }

      let profile = {
        dbId: this.props.profile.dbId,
        fullName: this.state.fullName,
        description: this.state.description,
        email: this.props.profile.email,
        profilePicKey: profilePicKey || this.props.profile.profilePicKey
      };

      await this.saveProfile(profile);
      this.props.childProps.onRefreshProfile();

      this.setState({ isLoading: false });
    }
    catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  render() {
      return (
        <>
          <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="fullName">
                <Form.Label>Name</Form.Label>
                <Form.Control onChange={this.handleChange} defaultValue={this.props.profile.fullName}/>
              </Form.Group>

              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control onChange={this.handleChange} defaultValue={this.props.profile.description}/>
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control readOnly defaultValue={this.props.profile.email}/>
              </Form.Group>

              <Avatar avatarsize="large" rounded currentprofile={this.props.profile}/>

              <Form.Group controlId="file">
                <Form.Label>Profile</Form.Label>
                <Form.Control onChange={this.handleProfilePicFileChange} type="file" />
              </Form.Group>

              <LoaderButton
                block
                bsstyle="primary"
                bssize="small"
                type="submit"
                isLoading={this.state.isLoading}
                text="Save"
                loadingText="Saving…"
              />
          </Form>
        </>
      );
  }
}
