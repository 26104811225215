import React from "react";
import { Route, Switch } from "react-router-dom";
import Feed from "./containers/Feed";
import NewEvent from "./containers/NewEvent";
import Friends from "./containers/Friends";
import Groups from "./containers/Groups";
import Group from "./containers/Group";
import User from "./containers/User";
import Profile from "./containers/Profile";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Feed} props={childProps} />
    <AuthenticatedRoute path="/feed" exact component={Feed} props={childProps} />
    <AuthenticatedRoute path="/events/new" exact component={NewEvent} props={childProps} />
    <AuthenticatedRoute path="/Friends" exact component={Friends} props={childProps} />
    <AuthenticatedRoute path="/Groups" exact component={Groups} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps} />
    <AuthenticatedRoute path="/Group/:groupId" exact component={Group} props={childProps} />
    <AuthenticatedRoute path="/User/:userId" exact component={User} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={Feed} />
  </Switch>;
