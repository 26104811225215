import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../containers/Footer.js"
import "./Careers.css"

export default class Careers extends Component {

  render() {
      return (
        <>
          <Container className="careers-container">
            <p>Here at Yompster we're always eager to talk to motivated superstars who are driven to change the nature of human to human interaction through technology.</p>
            <p>Send us a message at <a href="mailto:careers@yompster.com">careers@yompster.com</a></p>
          </Container>
          <Footer/>
        </>
      );
  }
}
