export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-west-2",
    BUCKET: "yompster-profile-pics"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://y1jdj6we5f.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_yTSzXjSYj",
    APP_CLIENT_ID: "5ubs5cuoj4jogc1r0fttvgsuuv",
    IDENTITY_POOL_ID: "us-west-2:8080939a-eef6-4f9d-a8e9-b32598bc2504"
  },
  social: {
      "FB": "166526390952511"
  }
};
