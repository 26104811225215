import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SignupPanel from "../components/SignupPanel";
import Footer from "../containers/Footer.js"
import "./Home.css";

export default class Home extends Component {

  handleLogin = () => {
    this.props.onRefreshProfile();
  }

  render() {
    return (
      <Fragment>
      <Container>
          <Row>
            <Col className="col-md-6 order-md-2 signupColumn">
              <SignupPanel onRefreshProfile={this.handleLogin}/>
            </Col>
            <Col className="d-none d-sm-block col-md-6 order-md-1 mb-6 enticementColumn">
              <Container>
                <img src="friends2.jpeg" width="550px" alt="Group of friends interacting in real life"></img>
                <div className="overlayText">The <b>REAL</b> social media</div>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer/>
        </Fragment>
    );
  }
}
