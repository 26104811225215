import React, { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default class Footer extends Component {

  render() {
      return (
        <Navbar sticky="bottom">
          <Nav className="mr-auto">
            <LinkContainer to="/about">
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <Nav.Link href="mailto:support@yompster.com">Support</Nav.Link>
            <LinkContainer to="/press">
              <Nav.Link>Press</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/careers">
              <Nav.Link>Careers</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/api">
              <Nav.Link>API</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/privacy">
              <Nav.Link>Privacy</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar>
      );
  }
}
