import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Footer from "../containers/Footer.js"
import "./AboutApi.css"

export default class AboutApi extends Component {

  render() {
      return (
        <>
          <Container className="api-container">
            Stay tuned for information about our upcoming API!
          </Container>
          <Footer/>
        </>
      );
  }
}
